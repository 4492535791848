import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, Observable } from 'rxjs'
import { JournalCreditModel, JournalDebitModel, AccountingModel } from '@domain/accounting'
import { MessageService } from 'primeng/api'
import { environment } from '@env'
import { PayingMethodModel } from '@domain/contact'

@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  constructor(private http: HttpClient) {
  }

  getBookings(): Observable<AccountingModel[]> {
    const url = `${environment.apiUrl}/rides`
    return this.http.get<AccountingModel[]>(url).pipe(
      map(value => {
        return value
      })
    )
  }

  getBookingsByEventId(eventId: number): Observable<AccountingModel[]> {
    const url = `${environment.apiUrl}/journal/events/${eventId}`
    return this.http.get<AccountingModel[]>(url).pipe(
      map(value => {
        return value
      })
    )
  }

  calcEventCosts(eventId: number): Observable<number> {
    const url = `${environment.apiUrl}/journal/events`
    return this.http.post<number>(url, eventId).pipe(
      map(value => {
        return value
      })
    )
  }

  addDebitsForEvent(debits: JournalDebitModel): Observable<number> {
    const url = `${environment.apiUrl}/journal/debits`
    return this.http.post<number>(url, debits).pipe(
      map(value => {
        return value
      })
    )
  }

  addCreditsForEvent(credits: JournalCreditModel): Observable<number> {
    const url = `${environment.apiUrl}/journal/credits`
    return this.http.post<number>(url, credits).pipe(
      map(value => {
        return value
      })
    )
    // catchError(error => {
    //   logger.error(`Error: $error`)
    //   return of(null)
    // }),
  }

  sendReceipt(eventId: number, emailAddressId?: number): Observable<number> {
    const url = `${environment.apiUrl}/journal/receipts/${eventId},${emailAddressId ?? 0}`
    return this.http.get<number>(url).pipe(
      map(value => {
        return value
      })
    )
  }

  getPayingMethods(): Observable<PayingMethodModel[]> {
    const url = `${environment.apiUrl}/journal/paying-methods`
    return this.http.get<PayingMethodModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }
}
